@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: rgb(250 250 252);
  color: rgb(32 45 73);
  font-size: 1rem;
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.5rem;
  font-weight: 400;
  text-rendering: optimizelegibility;
  position:relative;
  overflow-x:hidden;
}

html {
  scroll-behavior: smooth;
  position:relative;
  overflow-x:hidden;
}

h1, h2 {
  color: rgb(32 45 73);
  background-clip: border-box;
  -webkit-background-clip: text;
}

h3 {
  font-size: 1.4rem !important;
  background-color: rgb(32 45 73);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

input {
  height: 40px;
}

.gradient:focus {
  border: 1px solid #bfbfd9;
}

input,
textarea {
  background-color: #efeff5;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  padding: .5em;
}

*:focus:not(.focus-visible) {
  outline: none;
}

.emoji{
  -webkit-text-fill-color: #ffc83d;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 50% 100%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@media (min-width: 768px){
	.md\:w-6\/12 {
		width: 48% !important;
	}
}
.hright{
	width: 45%
}

.hleft{
	width: 55%
}

@media only screen and (max-width: 1280px) {
	body{
	  width: 95%;
	  margin: auto;
	}
	
	.hright{
      width: 60%
	}
	
	.hleft{
	  width: 40%
	}
	
	.p-8{
		padding: 0;
	}
	
	.mb-24{
		margin-bottom: 3rem;
	}
	
	.my-28{
	  margin-top: 3rem;
      margin-bottom: 3rem;
  }
  
  .my-44{
	  margin: auto;
	  padding: 0 2em;
  }
  
  .mouse{
	  display: none !important;
  }
  
  .text-4xl, .text-3xl{
	  font-size: 1.875rem;
	  line-height: 2.25rem;
  }
  
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  body{
	  width: 100%;
	  margin: 0px;
   }
   
   .p-8{
		padding: 2em;
	}
	
  .my-10{
      margin-left: 0;
      margin-right: 0;
	}
  .hero-content {
      flex-direction: column-reverse;
  }
  
  .hleft, .hright{
	  width: 100%
  }
  
  .mt-28, .mb-28{
	  margin-top: 0;
  }
  
  .my-44{
	  padding: 0 2rem;
	  margin-top: 0;
	  margin-bottom: 0;
  }
  
  .space-y-24 > :not([hidden]) ~ :not([hidden]){
	  --tw-space-y-reverse: 0;
      margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  
  .text-4xl, .text-3xl{
	font-size: 1.5rem;
    line-height: 2rem;
  }
  
  .my-5{
    margin-bottom: 0;
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.scroll-btn .mouse {
	position: relative;
	display: block;
	width: 25px;
	height: 40px;
	margin: 0 auto 20px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 2px solid rgb(32 45 73);
	border-radius: 23px;
}

.scroll-btn .mouse:hover > * {
	top: 71%;
	opacity: .4;
}

.scroll-btn .mouse > * {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 7px;
	height: 7px;
	margin: -4px 0 0 -4px;
	background: rgb(32 45 73);
	border-radius: 50%;
	transition: all .4s ease-out;
}

.border-gradient::after {
    position: absolute;
    top: -1px; bottom: -1px;
    left: -1px; right: -1px;
    background: -webkit-linear-gradient(right, rgba(227,187,81,1) 10%, rgba(57,151,223,1) 30%, rgba(213,91,134,1) 70%, rgba(227,187,81,1) 100%);
    background: -o-linear-gradient(right, rgba(227,187,81,1) 10%, rgba(57,151,223,1) 30%, rgba(213,91,134,1) 70%, rgba(227,187,81,1) 100%);
    background: linear-gradient(to left,rgba(227,187,81,1) 10%, rgba(57,151,223,1) 30%, rgba(213,91,134,1) 70%, rgba(227,187,81,1) 100%);
    content: '';
    z-index: -1;
    border-radius: 0.5rem;
    background-size: 150% 150%;
}
.border-gradient {
  overflow: visible;
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    background: #fafafc;
    background-clip: padding-box;
}

.border-gradient:hover:after{
  animation: animatedgradient 6s ease infinite;
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.link {
    position: relative;
    white-space: nowrap;
}

.link::before,
.link::after {
    position: absolute;
    width: 100%;
    height: 2px;
    background: -webkit-linear-gradient(right, rgba(227,187,81,1) 10%, rgba(57,151,223,1) 30%, rgba(213,91,134,1) 70%, rgba(227,187,81,1) 100%);
    background: -o-linear-gradient(right, rgba(227,187,81,1) 10%, rgba(57,151,223,1) 30%, rgba(213,91,134,1) 70%, rgba(227,187,81,1) 100%);
    background: linear-gradient(to left,rgba(227,187,81,1) 10%, rgba(57,151,223,1) 30%, rgba(213,91,134,1) 70%, rgba(227,187,81,1) 100%);
    top: 100%;
    left: 0;
    pointer-events: none;
    background-size: 150% 150%;
}

.link::before {
    content: '';
    /* show by default */
}

.link--metis::before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

.link--metis:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
    animation: animatedgradient 6s ease infinite;
}